// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../assets/video/klooster.mp4';
import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-32x32.png';
import '../assets/img/favicon/favicon-16x16.png';
import '../assets/img/logo/klooster.svg';
import '../assets/img/logo/klooster.png';
import '../assets/img/logo/klooster-white.svg';
import '../assets/img/logo/klooster-footer.svg';
import '../assets/img/svg/icon-facebook.svg';
import '../assets/img/svg/icon-instagram.svg';
import '../assets/img/svg/icon-linkedin.svg';
import '../assets/img/svg/icon-arrow.svg';
import '../assets/img/svg/icon-chevron.svg';
import '../assets/img/svg/icon-external.svg';
import '../assets/img/svg/icon-phone.svg';
import '../assets/img/svg/icon-mail.svg';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Start Turbo
Turbo.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}
